import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequireNumber } from '@/utils/validators';
import MultiLanguageInput from '@/components/DSE/MultiLanguageInput';
import ImageInput from '@/components/DSE/ImageInput';
import NumericInput from '@/components/DSE/NumericInput';
import Switch from '@/components/DSE/Switch';
import RestaurantSelector from '@/pages/masterdata/components/RestaurantSelector';
import Select from '@/components/DSE/Select';
import PriceInput from '../components/PriceInput';
import CrudMenuBlock from './menuBlocks/CrudMenuBlock';

export function getFormDefinition(vue) {
  return {
    type: 'tab',
    saveButtons: [true, false, false, false, false, false], // Page, tab 1, tab 2, ...
    showFormLabels: [true, true, false, true, true], // Tab 1, tab 2, ....
    labels: [
      'common.tabGeneral',
      'common.translations',
      'common.menuComposition',
      'common.nutritionValues',
      'common.restaurants'
    ],
    headerTemplate: (str, form) => `
    <h3>
    ${form.name || ''}
    </h3>
    `,
    groups: [
      {
        // Tab 1
        name: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true,
            infoTooltip: 'menu.nameTooltip'
          },
          rules: [{ validator: validatorRequire }]
        },
        menuCategoryID: {
          type: Select,
          defaultValue: null,
          props: {
            caption: 'common.menuCategory',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['menuCategory/isLoading'],
            options: vue.$store.getters['menuCategory/loadedItems']
          }
        },
        prices: {
          type: PriceInput,
          props: {
            caption: 'product.prices',
            infoTooltip: 'menu.pricesTooltip'
          }
        },
        takeawayID: {
          type: MDinput,
          props: {
            caption: 'menu.takeAwayID',
            type: 'number',
            precision: 0,
            showThousandSeparator: false,
            max: 99999999
          }
        },
        position: {
          type: NumericInput,
          defaultValue: 0,
          props: {
            type: 'number',
            precision: 0,
            caption: 'common.position'
          }
        },
        loyaltyPoints: {
          type: NumericInput,
          defaultValue: 0,
          props: {
            type: 'number',
            precision: 0,
            infoTooltip: 'menu.loyaltyPointsTooltip'
          },
          rules: [{ validator: validatorRequireNumber }]
        },
        image: {
          type: ImageInput,
          props: {
            height: 75,
            caption: 'common.image',
            infoTooltip: 'menu.imageTooltip'
          }
        },
        availableForThuisbezorgdYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'menu.availableForThuisbezorgd',
            activeText: 'common.active',
            inactiveText: 'common.inactive'
          }
        },
        onlineYN: {
          type: Switch,
          defaultValue: false,
          props: {
            activeText: 'common.active',
            inactiveText: 'common.inactive',
            infoTooltip: 'menu.onlineTooltip'
          }
        }
      },
      {
        // Tab 2 (Vertalingen)
        displayName: {
          type: MultiLanguageInput,
          props: {
            caption: 'menu.displayName',
            maxlength: 56
          }
        },
        nameOnTicket: {
          type: MultiLanguageInput,
          props: {
            caption: 'menu.nameOnTicket',
            maxlength: 36
          }
        },
        popupInfo: {
          type: MultiLanguageInput,
          props: {
            caption: 'menu.popupInfo',
            maxlength: 550,
            type: 'textarea'
          }
        },
        description: {
          type: MultiLanguageInput,
          props: {
            type: 'textarea',
            maxlength: 1010
          }
        },
        subtitle: {
          type: MultiLanguageInput,
          props: {
            caption: 'menu.subtitle',
            maxlength: 128
          }
        },
        extraProductLine: {
          type: MultiLanguageInput,
          props: {
            caption: 'menu.extraProductLine',
            infoTooltip: 'menu.extraProductLineTooltip',
            maxlength: 27
          }
        }
      },
      {
        // Tab 3 (menu samenstelling)
        menuBlocks: {
          hideOnCreate: true,
          type: CrudMenuBlock,
          defaultValue: [],
          props: {
            caption: 'common.emptyString',
            parentIdFilterField: 'menuID',
            permissionCode: 'Menu'
          }
        }
      },
      {
        // Tab 4 (Voedingswaarden)
        nutritionValuesUnit: {
          type: MDinput,
          defaultValue: '',
          props: {
            caption: 'menu.nutritionValuesUnit',
            maxlength: 64
          }
        },
        kiloJoule: {
          type: MDinput,
          defaultValue: 0,
          props: {
            caption: 'menu.kiloJoule',
            type: 'number',
            precision: 0
          }
        },
        kiloCalorie: {
          type: MDinput,
          defaultValue: 0,
          props: {
            caption: 'menu.kiloCalorie',
            type: 'number',
            precision: 0
          }
        },
        fat: {
          type: MDinput,
          defaultValue: 0,
          props: {
            caption: 'menu.fat',
            type: 'percentage',
            precision: 0
          }
        },
        digestibleCarbohydrates: {
          type: MDinput,
          defaultValue: 0,
          props: {
            caption: 'menu.digestibleCarbohydrates',
            type: 'percentage',
            precision: 0
          }
        },
        glutenYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'menu.gluten',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        cowsMilkProteinYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'menu.cowsMilkProtein',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        }
      },
      {
        // tab 5 (Restaurants)
        restaurants: {
          type: RestaurantSelector,
          props: {
            infoTooltip: 'menu.restaurantsTooltip',
            caption: 'coupon.restaurants'
          }
        }
      }
    ]
  };
}

export default getFormDefinition;

const layoutFullWidth = {
  sections: [
    {
      width: 100
    }
  ]
};

export function getCreateLayoutDefinition(vue) {
  return {
    groups: [layoutFullWidth, layoutFullWidth]
  };
}

export function getEditLayoutDefinition(vue) {
  return {
    groups: [layoutFullWidth, layoutFullWidth, layoutFullWidth, layoutFullWidth, layoutFullWidth]
  };
}

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
