var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tableContainer"},[_c('el-row',{staticClass:"page-buttons"},[_c('div',{staticClass:"buttons-left"},[_c('editor-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],attrs:{"action":"add"},on:{"click":_vm.addRow}})],1)]),_c('el-table',{ref:"menuBlockOptionList",staticStyle:{"width":"100%"},attrs:{"data":_vm.currentValue,"border":"","fit":"","highlight-current-row":"","stripe":""}},[_c('el-table-column',{attrs:{"label-class-name":"filter-cell"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return undefined}}])},[_c('el-table-column',{attrs:{"label":_vm.$t('menuBlockOption.product'),"width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"clearable":"","placeholder":_vm.$t('menuBlockOption.product')},on:{"input":function($event){return _vm.handleProductChanged(row)}},model:{value:(row.productID),callback:function ($$v) {_vm.$set(row, "productID", $$v)},expression:"row.productID"}},_vm._l((_vm.menuOptionCategories),function(group){return _c('el-option-group',{key:group.id,attrs:{"label":group.name}},_vm._l((group.products),function(item){return _c('el-option',{key:item.id,attrs:{"label":_vm.productDisplayName(item),"value":item.id}})}),1)}),1)]}}])})],1),_c('el-table-column',{attrs:{"label-class-name":"filter-cell"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return undefined}}])},[_c('el-table-column',{attrs:{"label":_vm.$t('menuBlockOption.numberOfProduct'),"width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('MDinput',{attrs:{"type":"number","precision":0},on:{"input":function($event){return _vm.handleNumberOfProductChanged(row)}},model:{value:(row['numberOfProduct']),callback:function ($$v) {_vm.$set(row, 'numberOfProduct', $$v)},expression:"row['numberOfProduct']"}})]}}])})],1),_c('el-table-column',{attrs:{"label":_vm.$t('menuBlockOption.displayName')}},_vm._l((_vm.languages),function(language){return _c('el-table-column',{key:language.id,attrs:{"label":_vm.$t('menuBlockOption.' + _vm.getDisplayNameFieldFromLanguage(language)),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('MDinput',{attrs:{"type":"text"},model:{value:(row[_vm.getDisplayNameFieldFromLanguage(language)]),callback:function ($$v) {_vm.$set(row, _vm.getDisplayNameFieldFromLanguage(language), $$v)},expression:"row[getDisplayNameFieldFromLanguage(language)]"}})]}}],null,true)})}),1),_c('el-table-column',{attrs:{"label":_vm.$t('menuBlockOption.price')}},_vm._l((_vm.priceCategories),function(priceCategory){return _c('el-table-column',{key:priceCategory.priceCategoryCode,attrs:{"label":_vm.$t('menuBlockOption.' + _vm.getPriceFieldFromPriceCategory(priceCategory)),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('MDinput',{attrs:{"type":"currency"},on:{"input":function($event){return _vm.handlePriceChanged(row, priceCategory)}},model:{value:(row[_vm.getPriceFieldFromPriceCategory(priceCategory)]),callback:function ($$v) {_vm.$set(row, _vm.getPriceFieldFromPriceCategory(priceCategory), $$v)},expression:"row[getPriceFieldFromPriceCategory(priceCategory)]"}}),(_vm.hasPriceError(row, priceCategory))?_c('div',{staticClass:"validation-errors"},[_vm._v(" "+_vm._s(_vm.$t('validation.notApplicable'))+" ")]):_vm._e()]}}],null,true)})}),1),_c('el-table-column',{attrs:{"label-class-name":"filter-cell"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return undefined}}])},[_c('el-table-column',{attrs:{"label":_vm.$t('menuBlockOption.default'),"width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ElCheckbox',{on:{"input":function($event){return _vm.handleDefaultChanged(row)}},model:{value:(row['defaultYN']),callback:function ($$v) {_vm.$set(row, 'defaultYN', $$v)},expression:"row['defaultYN']"}})]}}])})],1),_c('el-table-column',{attrs:{"label-class-name":"filter-cell"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return undefined}}])},[_c('el-table-column',{attrs:{"label":_vm.$t('menuBlockOption.sortOrder'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._t("actions",null,null,scope),_c('el-button',{attrs:{"disabled":!_vm.canMoveUp(scope.$index),"type":"primary","data-cy":"up","icon":"el-icon-arrow-up","circle":""},on:{"click":function($event){return _vm.handleMoveUp(scope)}}}),_c('el-button',{attrs:{"disabled":!_vm.canMoveDown(scope.$index),"type":"success","data-cy":"down","icon":"el-icon-arrow-down","circle":""},on:{"click":function($event){return _vm.handleMoveDown(scope)}}}),_c('el-button',{attrs:{"type":"danger","data-cy":"delete","icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.deleteConfirm(scope.$index, scope.row)}}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }