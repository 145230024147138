<template>
  <div ref="tableContainer">
    <el-row class="page-buttons">
      <div class="buttons-left">
        <editor-button v-show="!disabled" action="add" @click="addRow" />
      </div>
    </el-row>
    <el-table
      ref="menuBlockOptionList"
      :data="currentValue"
      border
      fit
      highlight-current-row
      style="width: 100%"
      stripe
    >
      <el-table-column label-class-name="filter-cell">
        <template slot="header" slot-scope="{}"></template>
        <el-table-column :label="$t('menuBlockOption.product')" width="250">
          <template slot-scope="{ row }">
            <el-select
              v-model="row.productID"
              clearable
              :placeholder="$t('menuBlockOption.product')"
              @input="handleProductChanged(row)"
            >
              <el-option-group v-for="group in menuOptionCategories" :key="group.id" :label="group.name">
                <el-option
                  v-for="item in group.products"
                  :key="item.id"
                  :label="productDisplayName(item)"
                  :value="item.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label-class-name="filter-cell">
        <template slot="header" slot-scope="{}"></template>
        <el-table-column :label="$t('menuBlockOption.numberOfProduct')" width="50">
          <template slot-scope="{ row }">
            <MDinput
              v-model="row['numberOfProduct']"
              type="number"
              :precision="0"
              @input="handleNumberOfProductChanged(row)"
            />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column :label="$t('menuBlockOption.displayName')">
        <el-table-column
          v-for="language in languages"
          :key="language.id"
          :label="$t('menuBlockOption.' + getDisplayNameFieldFromLanguage(language))"
          width="150"
        >
          <template slot-scope="{ row }">
            <MDinput v-model="row[getDisplayNameFieldFromLanguage(language)]" type="text" />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column :label="$t('menuBlockOption.price')">
        <el-table-column
          v-for="priceCategory in priceCategories"
          :key="priceCategory.priceCategoryCode"
          :label="$t('menuBlockOption.' + getPriceFieldFromPriceCategory(priceCategory))"
          width="100"
        >
          <template slot-scope="{ row }">
            <MDinput
              v-model="row[getPriceFieldFromPriceCategory(priceCategory)]"
              type="currency"
              @input="handlePriceChanged(row, priceCategory)"
            />
            <div v-if="hasPriceError(row, priceCategory)" class="validation-errors">
              {{ $t('validation.notApplicable') }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label-class-name="filter-cell">
        <template slot="header" slot-scope="{}"></template>
        <el-table-column :label="$t('menuBlockOption.default')" width="90">
          <template slot-scope="{ row }">
            <ElCheckbox v-model="row['defaultYN']" @input="handleDefaultChanged(row)" />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label-class-name="filter-cell">
        <template slot="header" slot-scope="{}"></template>
        <el-table-column :label="$t('menuBlockOption.sortOrder')" width="150">
          <template slot-scope="scope">
            <slot name="actions" v-bind="scope" />
            <el-button
              :disabled="!canMoveUp(scope.$index)"
              type="primary"
              data-cy="up"
              icon="el-icon-arrow-up"
              circle
              @click="handleMoveUp(scope)"
            />
            <el-button
              :disabled="!canMoveDown(scope.$index)"
              type="success"
              data-cy="down"
              icon="el-icon-arrow-down"
              circle
              @click="handleMoveDown(scope)"
            />
            <el-button
              type="danger"
              data-cy="delete"
              icon="el-icon-delete"
              circle
              @click="deleteConfirm(scope.$index, scope.row)"
            />
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import MDinput from '@/components/MDinput';
import EditorButton from '@/components/crud/EditorButton';
import { getMenuBlockTypeOptions } from '@/utils/constants';

export default {
  components: { MDinput, EditorButton },
  props: {
    value: {
      default() {
        return [];
      },
      type: [Array]
    },
    readonly: Boolean,
    disabled: Boolean,
    menuBlockType: Number
  },
  data() {
    return {
      currentValue: []
    };
  },
  computed: {
    menuOptionCategories() {
      const products = this.$store.getters['product/loadedItems']
        .filter(p => !p.toGoYN && p.onlineYN)
        .sort(p => (a, b) => {
          return a.position > b.position ? -1 : 1;
        });

      const menuBlockTypes = getMenuBlockTypeOptions(this).filter(e => e.code === this.menuBlockType);
      const menuOptionCategories = [];
      menuBlockTypes.forEach(menuBlockType => {
        const productsInCategory = products.filter(p => p.menuBlockType === menuBlockType.code);
        menuOptionCategories[menuBlockType.code] = {
          id: menuBlockType.code,
          name: menuBlockType.description,
          products: productsInCategory
        };
      });
      return menuOptionCategories.filter(x => typeof x === 'object');
    },
    priceCategories() {
      const priceCategories = this.$store.getters['priceCategory/loadedItems'];
      function nonMutatingSort(priceCategories) {
        const copy = [...priceCategories];
        copy.sort((a, b) => a.position - b.position);
        return copy;
      }

      return nonMutatingSort(priceCategories);
    },
    languages() {
      const languages = this.$store.getters['language/loadedItems'];

      function nonMutatingSort(languages) {
        const copy = [...languages];
        copy.sort((a, b) => a.position - b.position);
        return copy;
      }

      return nonMutatingSort(languages);
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    }
  },
  methods: {
    async handleProductChanged(row, product) {
      this.validate(row);
      this.$emit('input', this.value);
    },
    async handlePriceChanged(row, priceCategory) {
      console.log('handlePriceChanged');
      this.validate(row);
      this.$emit('input', this.value);
    },
    async handleNumberOfProductChanged(row) {
      this.validate(row);
      this.$emit('input', this.value);
    },
    async handleDefaultChanged(row) {
      this.validate(row);
      this.$emit('input', this.value);
    },
    getPriceFieldFromPriceCategory(priceCategory) {
      const priceCategoryCode = priceCategory.code;
      return 'price' + priceCategoryCode.charAt(0).toUpperCase() + priceCategoryCode.slice(1);
    },
    getDisplayNameFieldFromLanguage(language) {
      const languageCode = language.code;
      return 'displayName' + languageCode.charAt(0).toUpperCase() + languageCode.slice(1);
    },
    validate(row) {
      this.priceCategories.forEach(priceCategory => {
        const field = this.getPriceFieldFromPriceCategory(priceCategory);
        if (!+row.productID) {
          const hasError = `${row[field]}` !== 'undefined' && row[field] !== null && `${row[field]}`.length > 0;
          this.$set(row, `priceError_${field}`, hasError);
        } else {
          this.$set(row, `priceError_${field}`, false);
        }
      });
    },
    isValidAmount(value) {
      return !((value !== 0 && !value) || value.length === 0);
    },
    addRow() {
      const positions = this.value.map(e => e.position);
      const position = positions.reduce(function(a, b) {
        return Math.max(a, b);
      }, 0);
      const newValue = this.value;
      const newRow = {
        productID: null,
        position: position + 1,
        numberOfProduct: 1,
        name: '',
        defaultYN: false,
        displayNameDe: '',
        displayNameNl: '',
        prices: []
      };
      this.languages.forEach(e => {
        newRow[this.getDisplayNameFieldFromLanguage(e)] = null;
      });
      this.priceCategories.forEach(e => {
        newRow[this.getPriceFieldFromPriceCategory(e)] = null;
      });
      newValue.push(newRow);
      this.$emit('input', newValue);
      this.$refs.menuBlockOptionList.setCurrentRow(newRow);
    },
    handleMoveUp(scope) {
      const index = scope.$index;
      if (this.canMoveUp(index)) {
        this.swapItems(index - 1);
      }
    },
    handleMoveDown(scope) {
      const index = scope.$index;
      if (this.canMoveDown(index)) {
        this.swapItems(index);
      }
    },
    swapItems(startIndex) {
      const newValue = this.value;
      const valuesToSwap = newValue.slice(startIndex, startIndex + 2);
      valuesToSwap[0].position += 1;
      valuesToSwap[1].position -= 1;
      newValue.splice(startIndex, 2, valuesToSwap[1], valuesToSwap[0]);
      this.$emit('input', newValue);
    },
    canMoveUp(index) {
      return index > 0;
    },
    canMoveDown(index) {
      return index < this.currentValue.length - 1;
    },
    async deleteConfirm(index, row) {
      await this.$store.dispatch('notify/deleteConfirm');
      const newValue = this.currentValue;
      newValue.splice(index, 1);
      this.$emit('input', newValue);
    },
    hasPriceError(row, priceCategory) {
      const field = this.getPriceFieldFromPriceCategory(priceCategory);
      const key = `priceError_${field}`;
      return row[key];
    },
    productDisplayName(product) {
      let name = product.name;
      if (product.productCategoryName) {
        name = `${product.name} (${product.productCategoryName})`;
      }
      return name;
    }
  }
};
</script>

<style>
.el-dialog {
  min-width: 1500px;
}

.el-dialog__body {
  padding-top: 0;
}

.el-form-item__content {
  margin-left: 10px;
}

.el-table__body td {
  padding-top: 2px;
  padding-bottom: 4px;
}

.el-table th > .cell {
  word-break: break-word;
}

.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #d3dce6;
}

.createPost-container .el-form-item label {
  margin-top: 0;
}
</style>
