import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequireNumber } from '@/utils/validators';
import Select from '@/components/DSE/Select';
import Switch from '@/components/DSE/Switch';
import MultiLanguageInput from '@/components/DSE/MultiLanguageInput';
import NumericInput from '@/components/DSE/NumericInput';
import { getMenuBlockTypeOptions } from '@/utils/constants';
import MenuBlockOptionEditor from './MenuBlockOptionEditor';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    displayName: {
      type: MultiLanguageInput,
      props: {
        maxlength: 64,
        caption: 'common.displayName'
      }
    },
    menuBlockType: {
      type: Select,
      defaultValue: null,
      props: {
        caption: 'menu.menuBlockType',
        valueField: 'code',
        labelField: 'description',
        options: getMenuBlockTypeOptions(vue),
        required: true
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    position: {
      type: NumericInput,
      defaultValue: 0,
      props: {
        type: 'number',
        precision: 0,
        caption: 'menu.positionInMenu'
      }
    },
    onlineYN: {
      type: Switch,
      defaultValue: true,
      props: {
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    },
    menuBlockOptions: {
      type: MenuBlockOptionEditor,
      props: {
        caption: 'menu.menuBlockOptions'
      },
      dynamicProps: form => ({
        menuBlockType: form.menuBlockType
      })
    }
  };
}

export default getFormDefinition;

export function getSortedOptions(menuBlockOptions) {
  function nonMutatingSort(menuBlockOptions) {
    const copy = [...menuBlockOptions];
    copy.sort((a, b) => a.position - b.position);
    return copy;
  }
  return nonMutatingSort(menuBlockOptions);
}
export function createItemFromFormData(vue, form) {
  const item = {
    ...form
  };

  if (item.menuBlockOptions) {
    const priceCategories = vue.$store.getters['priceCategory/loadedItems'];
    const languages = vue.$store.getters['language/loadedItems'];
    item.menuBlockOptions.forEach(o => {
      if (o.productID === '') {
        o.productID = null;
      }
      priceCategories.forEach(c => {
        const value = o[getPriceKey(c.code)];
        if (value !== undefined && value !== null) {
          let price = o.prices.find(p => p.priceCategoryCode === c.code);
          if (price !== undefined && price.price !== value) {
            price.price = value;
          } else if (price === undefined) {
            price = createPrice(c, value, o);
            o.prices.push(price);
          }
        } else if (value === undefined || value === null) {
          const index = o.prices.findIndex(p => p.priceCategoryCode === c.code);
          if (index >= 0) {
            o.prices.splice(index, 1);
          }
        }
      });
      languages.forEach(l => {
        const value = o[getLanguageKey(l.code)];
        if (value !== undefined) {
          if (o.displayName === undefined || o.displayName === null) {
            o.displayName = { translatableID: 0, translations: [] };
          }
          const translation = o.displayName.translations.find(t => t.languageCode === l.code);
          if (translation !== undefined) {
            translation.text = value;
          } else {
            o.displayName.translations.push(createTranslation(l, value, o.displayName));
          }
        }
      });
    });
  }

  return item;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  const languageCodes = vue.$store.getters['language/loadedItems'].map(e => e.code);
  const priceCategoryCodes = vue.$store.getters['priceCategory/loadedItems'].map(e => e.code);

  form.menuBlockOptions = getSortedOptions(item.menuBlockOptions);
  form.menuBlockOptions.forEach(o => {
    languageCodes.forEach(e => {
      const translationText = getTranslationText(o.displayName, e);
      if (translationText !== undefined) {
        o[getLanguageKey(e)] = translationText;
      }
    });
    priceCategoryCodes.forEach(e => (o[getPriceKey(e)] = getPrice(o.prices, e)));
  });
  return form;
}
function createPrice(priceCategory, price, menuBlockOption) {
  return {
    menuBlockOptionID: menuBlockOption.menuBlockOptionID,
    price: price,
    priceCategoryCode: priceCategory.code,
    priceCategoryID: priceCategory.id,
    priceCategoryName: priceCategory.name
  };
}
function createTranslation(language, text, translatable) {
  return {
    languageCode: language.code,
    languageID: language.id,
    number: null,
    text: text,
    translatableID: translatable.translatableID
  };
}

function getPrice(prices, priceCategoryCode) {
  const price = prices.find(p => p.priceCategoryCode === priceCategoryCode);
  return price === undefined ? undefined : price.price;
}

function getTranslationText(displayName, languageCode) {
  if (displayName === undefined || displayName === null) {
    return undefined;
  }
  const translation = displayName.translations.find(t => t.languageCode === languageCode);
  return translation === undefined ? undefined : translation.text;
}

function getPriceKey(priceCategoryCode) {
  return 'price' + priceCategoryCode.charAt(0).toUpperCase() + priceCategoryCode.slice(1);
}

function getLanguageKey(languageCode) {
  return 'displayName' + languageCode.charAt(0).toUpperCase() + languageCode.slice(1);
}
