export const booleanColumnFilter = vue => ({
  type: 'select',
  options: [
    { value: 1, label: vue.$i18n.t('common.yes') },
    { value: 0, label: vue.$i18n.t('common.no') }
  ],
  valueField: 'value',
  labelField: 'label'
});

export const booleanColumnFormatter = vue => (row, column, cellValue) => {
  return cellValue ? vue.$i18n.t('common.yes') : vue.$i18n.t('common.no');
};
export const priceFormatter = (row, column, cellValue) => {
  if (isNaN(cellValue) || cellValue === null) {
    return '';
  }
  return '€ ' + cellValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const formatOption = (vue, optionCode) => (row, column, cellValue) => {
  const option =
    cellValue !== null && vue.$store.getters['option/loadedOptions'](optionCode).find(item => item.code === cellValue);
  if (!option) {
    return '';
  }
  return option.description;
};
