<template>
  <div>
    <span class="material-input-bar" />
    <label class="material-label">
      <slot />
    </label>
    <br v-if="$slots['default']" />
    <table class="material-table">
      <tr v-for="priceCategory in priceCategories" :key="priceCategory.id" class="material-table-tr">
        <th class="material-table-th-left">
          <label>{{ priceCategory.name }}</label>
        </th>
        <th class="material-table-th-right">
          <MDinput
            :key="priceCategory.id"
            v-model="currentValue[priceCategory.id]"
            :loading="priceCategoriesLoading"
            type="currency"
            v-bind="$attrs"
            :translate-placeholder="false"
            @input="handleInput($event, priceCategory)"
          />
        </th>
      </tr>
    </table>
  </div>
</template>
<script>
import MDinput from '@/components/MDinput';

export default {
  components: {
    MDinput
  },
  props: {
    value: {
      default() {
        return [];
      },
      type: [Array]
    }
  },
  data() {
    return {
      currentValue: []
    };
  },
  computed: {
    priceCategories() {
      return this.$store.getters['priceCategory/loadedItems'];
    },
    priceCategoriesLoading() {
      return this.$store.getters['priceCategory/isLoading'];
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = [];
      if (newValue) {
        newValue.forEach(productPrice => {
          this.currentValue[productPrice.priceCategoryID] = productPrice.price;
        });
      }
    }
  },
  methods: {
    handleInput(price, priceCategory) {
      const newValue = this.value;
      const productPrice = newValue && newValue.find(t => t.priceCategoryID === priceCategory.id);
      if (productPrice) {
        if (price == null || price === '') {
          newValue.splice(newValue.indexOf(productPrice), 1);
        } else {
          productPrice.price = price;
        }
      } else {
        newValue.push({
          priceCategoryID: priceCategory.id,
          price: price
        });
      }
      this.$emit('input', newValue);
    }
  }
};
</script>
