import { render, staticRenderFns } from "./CrudMenuBlock.vue?vue&type=template&id=188a6734&scoped=true&"
import script from "./CrudMenuBlock.vue?vue&type=script&lang=js&"
export * from "./CrudMenuBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188a6734",
  null
  
)

export default component.exports