<template>
  <div>
    <crud-table
      ref="table"
      :entity="entity"
      :columns="columns"
      :allow-delete="allowDelete"
      :allow-create="allowCreate"
      :allow-actions="!disabled"
      :disable-filtering="disableFiltering"
      :disable-sorting="disableSorting"
      :loading="loading"
      :on-row-dbl-click="onTableRowDoubleClick"
      :on-create="() => {}"
      :on-edit="() => {}"
      :permission-code="permissionCode"
      :filters="parentIdentifierObject"
      :default-filters="defaultFilters"
      :generate-summary="generateSummary"
      :on-open-context-menu="onOpenContextMenu"
      :on-context-menu-option-clicked="onContextMenuOptionClicked"
      :use-pagination="usePagination"
      is-embedded
      :summary-method="summaryMethod"
      :show-summary="showSummary"
      :is-disabled-delete="isDisabledDelete"
      @create="showCreate"
      @edit="showEdit"
      @current-change="handleCurrentChange"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </crud-table>
    <dialog-editor
      ref="dialog"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :definition="definition"
      :save="saveData"
      :page-loading="loading"
      :force-values="parentIdentifierObject"
      v-bind="editorProps"
      @saved="handleSaved"
    />
  </div>
</template>
<script>
import CrudTable from '@/components/crud/CrudTable';
import DialogEditor from '@/components/crud/DialogEditor';

export default {
  components: {
    CrudTable,
    DialogEditor
  },
  props: {
    entity: {
      type: String,
      required: true
    },
    entityStore: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    generateSummary: {
      type: Function,
      required: true
    },
    onOpenContextMenu: {
      type: Function
    },
    onContextMenuOptionClicked: {
      type: Function
    },
    definition: {
      type: Object,
      required: true
    },
    createItemFromFormData: {
      type: Function,
      required: true
    },
    createFormDataFromItem: {
      type: Function,
      required: true
    },
    // Used for request filtering and forced form value
    // eg. parent of RentalAgreement is clientID
    parentIdFilterField: {
      type: String,
      required: false
    },
    parentIdentifiers: {
      type: Object,
      default: () => null
    },
    permissionCode: [String, Array],
    caption: String,
    disabled: {
      default: false,
      type: Boolean
    },
    openInFullscreen: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    },
    disableFiltering: {
      default: false,
      type: Boolean
    },
    disableSorting: {
      default: false,
      type: Boolean
    },
    allowDelete: {
      default: true,
      type: Boolean
    },
    allowCreate: {
      default: true,
      type: Boolean
    },
    usePagination: {
      type: Boolean,
      default: false
    },
    editorNumber: Number,
    refreshOnFormSave: Boolean,
    refreshOnItemSave: {
      type: Boolean,
      default: true
    },
    summaryMethod: {
      type: Function
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    defaultFilters: {
      type: Object,
      default: () => ({})
    },
    /**
     * Returns the disabled state of delete button
     * @param {Object} row Owner row of the delete button
     * @returns {Boolean}
     */
    isDisabledDelete: Function
  },
  data() {
    return {
      dialogVisible: false,
      editorProps: {},
      dialogTitle: ''
    };
  },
  computed: {
    loading() {
      return this.$store.getters[this.entityStore + '/isLoading'];
    },
    internalParentID() {
      return this.parentID || (this.$route.params && +this.$route.params.id);
    },
    parentIdentifierObject() {
      if (this.parentIdentifiers) {
        return this.parentIdentifiers;
      }
      const obj = {};
      if (this.parentIdFilterField) {
        obj[this.parentIdFilterField] = this.internalParentID;
      }
      return obj;
    }
  },
  mounted() {
    this.$root.$on('editor-saved', this.formSaveRefreshHook);
  },
  beforeDestroy() {
    this.$root.$off('editor-saved', this.formSaveRefreshHook);
  },
  methods: {
    formSaveRefreshHook(editorNumber, form) {
      if (this.refreshOnFormSave && editorNumber === this.editorNumber) {
        this.refresh();
      }
    },
    showCreate() {
      if (this.disabled) {
        return false;
      }
      this.editorProps = { isEdit: false };
      this.dialogVisible = true;
      this.dialogTitle = this.$t('route.create' + this.entity);
      if (this.$refs.dialog) {
        this.$refs.dialog.clearForm();
      }
    },
    showEdit(id) {
      if (this.disabled) {
        return false;
      }
      this.dialogTitle = this.$t('route.edit' + this.entity);
      this.editorProps = { id, isEdit: true, fetchById: this.fetchById };
      this.dialogVisible = true;
    },
    handleSaved() {
      this.dialogVisible = false;
      if (this.refreshOnItemSave) {
        this.refresh();
      }
    },
    handleCurrentChange(currentRow, oldCurrentRow) {
      this.$emit('current-change', currentRow);
    },
    onTableRowDoubleClick(row) {
      this.showEdit(row.id);
    },
    saveData(form) {
      const item = this.createItemFromFormData(this, form);
      return this.$store.dispatch(this.entityStore + '/persistItem', item);
    },
    fetchById(id) {
      return this.$store
        .dispatch(this.entityStore + '/getItem', { selector: { id }, options: { dontSave: true } })
        .then(item => this.createFormDataFromItem(this, item));
    },
    refresh() {
      if (this.$refs.table) {
        this.$refs.table.refreshList();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
